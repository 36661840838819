import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { store } from '../../Store'
import { useActions } from '../../Action'
import Help from '../../assets/ic-question.png'
import LabeledInputField from '../../utils/custom-fields'
import Button from '../common/button'

const ConfirmUpgrade = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { isTradeIdError } = state
  const { register, errors } = useForm({ mode: 'onChange' })
  const [upgradeId, setUpgradeId] = useState('')
  const [payRollId, setPayRollId] = useState('')
  const [payRollIdValidation, setPayRollIdValidationRes] = useState({ isPayRollIdValid: true, errorMsg: '' })
  const [upgradeIdValidation, setUpgradeIdValidationRes] = useState({ isUpgradeIdValid: true, errorMsg: '' })

  const validatePayRollId = (value) => {
    let validationResult = { isPayRollIdValid: true, errorMsg: '' }
    if (value === '') {
      validationResult = { isPayRollIdValid: false, errorMsg: 'Payroll ID is required.' }
    } else if (value.length < 6) {
      validationResult = { isPayRollIdValid: false, errorMsg: 'Payroll ID should be at least 6 characters long.' }
    } else if (value.length > 7) {
      validationResult = { isPayRollIdValid: false, errorMsg: 'Payroll ID should be at most 7 characters long.' }
    }
    return validationResult
  }
  const validateUpgradeId = (value) => {
    let validationResult = { isUpgradeIdValid: true, errorMsg: '' }
    if (value === '') {
      validationResult = { isUpgradeIdValid: false, errorMsg: 'Trade ID is required.' }
    }
    return validationResult
  }

  const findUpgradeDetails = () => {
    actions.getTradeDetails(upgradeId, payRollId)
  }

  const isEmpty = (input) => {
    if (typeof input === 'undefined' || input === 'null') {
      return true
    }
    if (typeof input === 'function') {
      return false
    }
    if (Array.isArray(input)) {
      return input.length === 0
    }
    return !input || Object.keys(input).length === 0
  }

  const handleClear = () => {
    setUpgradeId('')
    setPayRollId('')
    setPayRollIdValidationRes({ isPayRollIdValid: true, errorMsg: '' })
    setUpgradeIdValidationRes({ isUpgradeIdValid: true, errorMsg: '' })
    actions.clearTradeDetails()
    isTradeIdError && actions.clearError()
  }

  return (
    <div>
      <div className="font-bold text-2xl mt-50 text-center">Confirm Trade-in</div>
      <div className="flex flex-col w-full">
        <p className="text-lg my-30 text-center">Trade ID will be provided by the customer via their Email or Trade-in portal</p>
        {/* <form onSubmit={handleSubmit} className="container"> */}
        <div className="flex flex-row justify-center">
          <div>
            <div className="flex-col flex">
              <div className="mt-0 md:mt-5 tooltip">
                <div className="flex flex-row ml-2">
                  <span className="text-base leading-19 font-AvenirNextRegular text-dark-gray">Payroll ID*</span>
                  <img src={Help} className="tooltip cursor-pointer ml-1 object-contain" />
                </div>
                <span className="tooltiptext font-normal text-center" style={{ marginLeft: '-62px', fontSize: '14px' }}>
                  Please enter your 6-7 character Payroll ID.
                </span>
              </div>
              <LabeledInputField
                type="text"
                name="payRollId"
                label=""
                placeholder=""
                className="mt-1 font-AvenirNextRegular"
                value={payRollId}
                register={register}
                errors={errors}
                schema="verification"
                hideLabel
                maxLength={7}
                onChange={(e) => {
                  setPayRollId(e)
                  const value = e
                  const validationResult = validatePayRollId(value)
                  setPayRollIdValidationRes(validationResult)
                }}
              />
            </div>
            {!payRollIdValidation?.isPayRollIdValid && <p style={{ fontSize: '12px', color: 'red', marginTop: '2px' }}>{payRollIdValidation?.errorMsg}</p>}
          </div>
          <div className="flex-col flex ml-5">
            <div className="mt-0 md:mt-5 tooltip">
              <div className="flex flex-row ml-2">
                <span className="text-base leading-19 font-AvenirNextRegular text-dark-gray">Trade ID*</span>
              </div>
            </div>
            <LabeledInputField
              type="text"
              name="upgradeId"
              labelClass=""
              label=""
              placeholder=""
              className="mt-1"
              value={upgradeId}
              onChange={(e) => {
                setUpgradeId(e)
                const value = e
                const validationResult = validateUpgradeId(value)
                setUpgradeIdValidationRes(validationResult)
                isTradeIdError && actions.clearError()
              }}
              register={register}
              errors={errors}
              hideLabel
            />
            {!upgradeIdValidation?.isUpgradeIdValid && !isTradeIdError && <p style={{ fontSize: '12px', color: 'red', marginTop: '2px' }}>{upgradeIdValidation?.errorMsg}</p>}
            {isTradeIdError && <p style={{ fontSize: '12px', color: 'red', marginTop: '2px' }}>Please enter correct Trade ID</p>}
          </div>
        </div>
        <div className="flex flex-row my-30 items-center justify-center">
          <Button
            onClick={() => handleClear()}
            otherClass={'text-sm bg-white border border-lightBlue'}
            textClass={'text-lightBlue'}
            disabled={false}
            ignoreDisable={true}
            label={'CLEAR'}
            padding="px-22 py-10 ml-90"
          />
          <Button
            otherClass="active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm ml-5"
            label="SEARCH"
            padding="px-16 py-11"
            onClick={findUpgradeDetails}
            disabled={payRollId?.length === 0 || upgradeId?.length === 0 || !payRollIdValidation?.isPayRollIdValid || !upgradeIdValidation?.isUpgradeIdValid || isTradeIdError}
          />
        </div>
        {/* </form> */}
      </div>
    </div>
  )
}

export default ConfirmUpgrade
